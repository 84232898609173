<template>
  <div class="container">
    <div class="top" id="bloc-0">
      <voice-header></voice-header>
    </div>

    <div class="main sound-effect-page">
      <div class="hero-section">
        <div class="hero-content">
          <h1>{{ $t('soundeffect.hero_title') }}</h1>
          <p class="hero-subtitle">{{ $t('soundeffect.hero_subtitle') }}</p>
        </div>
      </div>

      <!-- 试听区域 -->
      <div class="sound-samples-wrapper">
        <div class="sound-samples">
          <div class="sound-samples-inner">
            <h2 class="sound-samples-title">{{ $t('soundeffect.samples_title') }}</h2>

            <!-- 分类导航栏 -->
            <div class="categories-tabs">
              <button
                v-for="category in categories"
                :key="category.id"
                :class="['category-tab', { active: currentCategory === category.id }]"
                :data-category="category.id"
                @click="currentCategory = category.id"
              >
                {{ $t(`soundeffect.category_${category.id}`) }}
              </button>
            </div>

            <!-- 音效卡片网格 -->
            <div class="sound-cards-grid">
              <div
                v-for="sample in currentSamples"
                :key="sample.id"
                class="sound-card"
                :data-category="currentCategory"
                @click="playSample(sample)"
                :class="{ 'is-playing': currentPlaying === sample.id }"
              >
                <div class="sound-card-content">
                  <h3 class="sound-title">
                    <span class="emoji">{{ sample.emoji }}</span>
                    {{ $t(`soundeffect.sample_${sample.id}`) }}
                  </h3>
                  <button
                    class="play-btn"
                    aria-label="AI sound generator play button"
                    :data-category="currentCategory"
                    :class="{ 'loading': isLoadingSample === sample.id }"
                  >
                    <!-- 加载中状态 -->
                    <svg v-if="isLoadingSample === sample.id" class="loading-icon" viewBox="0 0 24 24" width="24" height="24">
                      <path fill="currentColor" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                    </svg>
                    <!-- 播放/暂停状态 -->
                    <svg v-else-if="currentPlaying === sample.id" class="pause-icon" viewBox="0 0 24 24" width="24" height="24">
                      <path fill="currentColor" d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>
                    </svg>
                    <!-- 默认状态 -->
                    <svg v-else class="play-icon" viewBox="0 0 24 24" width="24" height="24">
                      <path fill="currentColor" d="M8 5v14l11-7z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-container">
        <div class="input-container">
          <div class="title-group">
            <h2 class="generator-title">{{ $t('soundeffect.generator_title') }}</h2>
            <p class="generator-subtitle">{{ $t('soundeffect.generator_subtitle') }}</p>
          </div>

          <!-- 标签展示部分 -->
          <div class="prompt-tags">
            <button
              v-for="(prompt, index) in promptExamples"
              :key="index"
              class="prompt-tag"
              @click="selectPrompt(prompt.key)"
            >
              {{ $t(prompt.display) }}
            </button>
          </div>

          <!-- 然后是文本框 -->
          <div class="textarea-wrapper">
            <textarea
              v-model="description"
              class="effect-input"
              aria-label="AI sound generator textarea"
              maxlength="100"
              rows="3"
            ></textarea>
            <div class="char-counter" :class="{ 'near-limit': description.length > 80 }">
              {{ description.length }}/100
            </div>
          </div>

          <div class="controls">
            <button
              class="generate-btn"
              @click="generateSound"
              :disabled="isGenerating"
            >
              <div class="generate-btn-content">
                <svg v-if="isGenerating" class="loading-icon" viewBox="0 0 24 24" width="20" height="20">
                  <path fill="currentColor" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                </svg>
                <span v-else>{{ $t('soundeffect.btn_generate') }}</span>
              </div>
            </button>
            <!-- button class="settings-btn" @click="openSettings">Settings</button -->
          </div>
        </div>

        <div class="generated-sounds" v-if="showAudioControls">
          <div class="sound-item">
            <div class="sound-info">
              <button class="play-button" @click="playSound">
                <span v-if="!isPlayLoading">
                  <svg v-if="!isPlaying" class="play-icon" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="currentColor" d="M8 5v14l11-7z"/>
                  </svg>
                  <svg v-else class="pause-icon" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="currentColor" d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>
                 </svg>
                </span>
                <svg v-else class="loading-icon" viewBox="0 0 24 24" width="20" height="20">
                  <path fill="currentColor" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                </svg>
              </button>

              <div class="progress-container" ref="progressContainer" @click="seek" @mousedown="startDrag">
                <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
                <div class="progress-handle" :style="{ left: `${progress}%` }"></div>
              </div>

              <span class="time-display">{{ formatTime(audioCurrentTime) }} / {{ formatTime(audioDuration) }}</span>
            </div>
            <button class="download-button"
                @click="handleDownload"
                :disabled="!generatedSound || isDownloading"
                :class="{ 'downloading': isDownloading }"
                > {{ isDownloading ? $t('soundeffect.btn_downloading') : $t('soundeffect.btn_download') }}
              </button>
          </div>
        </div>
      </div>
      <audio ref="audio_main" style="display: none;">
      </audio>

      <!-- Features Section -->
      <div class="advantages-section">
        <div class="advantages-container">
          <h2 class="advantages-title">{{ $t('soundeffect.advantages_title') }}</h2>
          <div class="advantages-grid">
            <div class="advantage-card">
              <div class="advantage-icon">⚡</div>
              <h3>{{ $t('soundeffect.advantage_1_title') }}</h3>
              <p>{{ $t('soundeffect.advantage_1_desc') }}</p>
            </div>

            <div class="advantage-card">
              <div class="advantage-icon">🎵</div>
              <h3>{{ $t('soundeffect.advantage_2_title') }}</h3>
              <p>{{ $t('soundeffect.advantage_2_desc') }}</p>
            </div>

            <div class="advantage-card">
              <div class="advantage-icon">🎯</div>
              <h3>{{ $t('soundeffect.advantage_3_title') }}</h3>
              <p>{{ $t('soundeffect.advantage_3_desc') }}</p>
            </div>

            <div class="advantage-card">
              <div class="advantage-icon">✅</div>
              <h3>{{ $t('soundeffect.advantage_4_title') }}</h3>
              <p>{{ $t('soundeffect.advantage_4_desc') }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- FAQ Section -->
      <div class="faq-section">
        <h2>{{ $t('soundeffect.faq_title') }}</h2>
        <div class="faq-grid">
          <div
            v-for="(faq, index) in $t('soundeffect.faqs')"
            :key="index"
            class="faq-item"
            :class="{ 'active': openFaqIndex === index }"
          >
            <div class="faq-question" @click="toggleFaq(index)">
              <h3>{{ $t(`soundeffect.faqs.${index}.question`) }}</h3>
              <svg
                class="arrow-icon"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                :class="{ 'rotated': openFaqIndex === index }"
              >
                <path fill="currentColor" d="M7 10l5 5 5-5H7z"/>
              </svg>
            </div>
            <div class="faq-answer" v-show="openFaqIndex === index">
              <p>{{ $t(`soundeffect.faqs.${index}.answer`) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="popupContainer">
        <PopupModal
            v-for="(data, id) in popupData"
            :key="id"
            :data="data"
            :pricing-href="pricing_href"
            v-if="popupStates[id]"
            @close="closePopup(id)"
        />
    </div>
    <div class="footer" id="bloc-10">
        <div>
            <voice-footer></voice-footer>
        </div>
    </div>
    <PopupDownload ref="popupDownload" />
    <GoogleSignInModal
        :visible.sync="isSignInVisible"
        @credential-response="handleCredentialResponse"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import axios from 'axios'
import VoiceHeader from '../components/VoiceHeader.vue'
import { handleGoogleAuth } from '../utils/auth'
import { reportError } from '../utils/errorReporter'
import { setCookie, delCookie, getCookie } from '../utils/cookies';
import { trackAction } from '../utils/actionReporter'

const VoiceFooter = defineAsyncComponent(() => import('../components/VoiceFooterIndex.vue'))
const PopupModal = defineAsyncComponent(() => import('@/components/PopupModal.vue'))
const PopupDownload = defineAsyncComponent(() => import('@/components/PopupDownload.vue'))
const GoogleSignInModal = defineAsyncComponent(() => import('@/components/GoogleSignInModal.vue'))

const api_host = 'https://tiktokvoice.net'

// 静态配置
const STATIC_CONFIG = Object.freeze({
    text_maxlen: 100,
    POPUP_TYPES: {
        NOLOGIN: 'popup_nologin',
        NOSUB: 'popup_nosub',
        SUBLIMIT: 'popup_sublimit'
    }
})

export default {
  name: 'soundseffect',
  components: {
    VoiceHeader,
    VoiceFooter,
    PopupModal,
    PopupDownload,
    GoogleSignInModal
  },
  data() {
    return {
      description: '',
      generatedSound: null,
      isGenerating: false,
      showAudioControls: false,
      isPlaying: false,
      isDownloading: false,
      isPlayLoading: false,
      pricing_href: '/en/pricing',
      email: '',
      user_subscript: 0,
      openFaqIndex: null,
      promptExamples: [
        // Combat & UI
        { key: 'sword whoosh', display: 'soundeffect.prompt_combat.sword_whoosh' },
        { key: 'sword whooshing through the air', display: 'soundeffect.prompt_combat.sword_through_air' },
        { key: 'shotgun fire', display: 'soundeffect.prompt_combat.shotgun_fire' },
        { key: 'futuristic laser gunshots', display: 'soundeffect.prompt_combat.laser_gunshot' },
        { key: 'user interface success notifications', display: 'soundeffect.prompt_combat.ui_success' },

        // Nature
        { key: 'Rain', display: 'soundeffect.prompt_nature.rain' },
        { key: 'ocean waves', display: 'soundeffect.prompt_nature.ocean_waves' },
        { key: 'flowing water', display: 'soundeffect.prompt_nature.flowing_water' },

        // Special Effects
        { key: 'Fireworks', display: 'soundeffect.prompt_special.fireworks' },
        { key: 'glass shattering', display: 'soundeffect.prompt_special.glass_shattering' },
        { key: 'magic spell', display: 'soundeffect.prompt_special.magic_spell' },

        // Instruments
        { key: 'Piano', display: 'soundeffect.prompt_instruments.piano' },
        { key: 'electric guitar', display: 'soundeffect.prompt_instruments.electric_guitar' },
        { key: 'Violin', display: 'soundeffect.prompt_instruments.violin' },

        // Human Sounds
        { key: 'baby laughing', display: 'soundeffect.prompt_human.baby_laughing' },
        { key: 'Clapping', display: 'soundeffect.prompt_human.clapping' },
        { key: 'Celebrate', display: 'soundeffect.prompt_human.celebrate' },

        // Ambient
        { key: 'Typing', display: 'soundeffect.prompt_ambient.typing' },
        { key: 'noisy restaurant', display: 'soundeffect.prompt_ambient.noisy_restaurant' },
        { key: 'doorbell ring', display: 'soundeffect.prompt_ambient.doorbell_ring' }
      ],
      currentCategory: 'nature',
      currentPlaying: null,
      categories: [
        { id: 'nature', name: 'Nature Sound' },
        { id: 'special', name: 'Special Effects' },
        { id: 'instrument', name: 'Instrument' },
        { id: 'human', name: 'Human Sound' },
        { id: 'ambient', name: 'Ambient Sounds' }
      ],
      samples: {
        nature: [
          { id: 'rain', emoji: '🌧️', url: '/outimage/wavplay/sounds/nature/rain.mp3' },
          { id: 'ocean', emoji: '🌊', url: '/outimage/wavplay/sounds/nature/ocean.mp3' },
          { id: 'water', emoji: '💧', url: '/outimage/wavplay/sounds/nature/water.mp3' },
          { id: 'thunder', emoji: '⚡', url: '/outimage/wavplay/sounds/nature/thunder.mp3' },
          { id: 'insect', emoji: '🦗', url: '/outimage/wavplay/sounds/nature/insect.mp3' }
        ],
        special: [
          { id: 'fireworks', emoji: '🎆', url: '/outimage/wavplay/sounds/special/fireworks.mp3' },
          { id: 'glass', emoji: '💥', url: '/outimage/wavplay/sounds/special/glass.mp3' },
          { id: 'magic', emoji: '✨', url: '/outimage/wavplay/sounds/special/magic.mp3' },
          { id: 'spaceship', emoji: '🚀', url: '/outimage/wavplay/sounds/special/spaceship.mp3' },
          { id: 'action', emoji: '💫', url: '/outimage/wavplay/sounds/special/action.mp3' }
        ],
        instrument: [
          { id: 'piano', emoji: '🎹', url: '/outimage/wavplay/sounds/instrument/piano.mp3' },
          { id: 'guitar', emoji: '🎸', url: '/outimage/wavplay/sounds/instrument/guitar.mp3' },
          { id: 'violin', emoji: '🎻', url: '/outimage/wavplay/sounds/instrument/violin.mp3' },
          { id: 'keyboard', emoji: '🎹', url: '/outimage/wavplay/sounds/instrument/keyboard.mp3' },
          { id: 'pipes', emoji: '🎵', url: '/outimage/wavplay/sounds/instrument/pipes.mp3' }
        ],
        human: [
          { id: 'baby', emoji: '👶', url: '/outimage/wavplay/sounds/human/baby.mp3' },
          { id: 'clap', emoji: '👏', url: '/outimage/wavplay/sounds/human/clap.mp3' },
          { id: 'celebrate', emoji: '🎉', url: '/outimage/wavplay/sounds/human/celebrate.mp3' },
          { id: 'footsteps', emoji: '👣', url: '/outimage/wavplay/sounds/human/footsteps.mp3' },
          { id: 'burp', emoji: '😮', url: '/outimage/wavplay/sounds/human/burp.mp3' }
        ],
        ambient: [
          { id: 'typing', emoji: '⌨️', url: '/outimage/wavplay/sounds/ambient/typing.mp3' },
          { id: 'restaurant', emoji: '🍽️', url: '/outimage/wavplay/sounds/ambient/restaurant.mp3' },
          { id: 'doorbell', emoji: '🔔', url: '/outimage/wavplay/sounds/ambient/doorbell.mp3' },
          { id: 'tv', emoji: '📺', url: '/outimage/wavplay/sounds/ambient/tv.mp3' },
          { id: 'cooking', emoji: '🍳', url: '/outimage/wavplay/sounds/ambient/cooking.mp3' }
        ]
      },
      isLoadingSample: null,
      progress: 0,
      isDragging: false,
      audioCurrentTime: 0,
      audioDuration: 0,
      isSignInVisible: false,
      popupData: {
        // 未登录用户
        [STATIC_CONFIG.POPUP_TYPES.NOLOGIN]: {
          title: this.$i18n.t('txt2voice.popup_nologin_title'),
          description: this.$i18n.t('txt2voice.popup_nologin_desc'),
          buttonText: this.$i18n.t('txt2voice.popup_nologin_btntext'),
        },
        // 登录用户,未订阅
        [STATIC_CONFIG.POPUP_TYPES.NOSUB]: {
          title: this.$i18n.t('txt2voice.popup_nosub_title'),
          description: this.$i18n.t('txt2voice.popup_nosub_desc'),
          buttonText: this.$i18n.t('txt2voice.popup_nosub_btntext'),
        },
        // 登录用户, 已达到订阅套餐上限
        [STATIC_CONFIG.POPUP_TYPES.SUBLIMIT]: {
          title: this.$i18n.t('txt2voice.popup_sublimit_title'),
          description: this.$i18n.t('txt2voice.popup_sublimit_desc'),
          buttonText: this.$i18n.t('txt2voice.popup_sublimit_btntext'),
        }
      },
      popupStates: {},
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser', 'authToken']),
    currentSamples() {
      return this.samples[this.currentCategory] || []
    },
    currentTime() {
      return this.formatTime(this.$refs.audio_main?.currentTime || 0)
    },
    duration() {
      return this.formatTime(this.$refs.audio_main?.duration || 0)
    }
  },
  watch: {
    isLoggedIn(newValue) {
      if (newValue && this.currentUser) {
        this.getUserInfo(this.currentUser.email);
      }
    },
  },
  head() {
    return {
      title: this.$t('soundeffect.title'),
      keywords: this.$t('soundeffect.keywords'),
      description: this.$t('soundeffect.description'),
    }
  },
  metaInfo() {
    return {
      link: [
        { rel: 'alternate', hreflang: 'x-default', href: 'https://tiktokvoice.net/en/sounds-effect' },
        { rel: 'alternate', hreflang: 'en', href: 'https://tiktokvoice.net/en/sounds-effect' },
        { rel: 'alternate', hreflang: 'ja', href: 'https://tiktokvoice.net/ja/sounds-effect' },
        { rel: 'alternate', hreflang: 'zh', href: 'https://tiktokvoice.net/zh/sounds-effect' },
        { rel: 'alternate', hreflang: 'zh-tw', href: 'https://tiktokvoice.net/zh-tw/sounds-effect' },
        { rel: 'alternate', hreflang: 'ko', href: 'https://tiktokvoice.net/ko/sounds-effect' },
        { rel: 'alternate', hreflang: 'vi', href: 'https://tiktokvoice.net/vi/sounds-effect' },
        { rel: 'alternate', hreflang: 'th', href: 'https://tiktokvoice.net/th/sounds-effect' },
        { rel: 'alternate', hreflang: 'hi', href: 'https://tiktokvoice.net/hi/sounds-effect' },
        { rel: 'alternate', hreflang: 'fa', href: 'https://tiktokvoice.net/fa/sounds-effect' },
        { rel: 'alternate', hreflang: 'ru', href: 'https://tiktokvoice.net/ru/sounds-effect' },
        { rel: 'alternate', hreflang: 'de', href: 'https://tiktokvoice.net/de/sounds-effect' },
        { rel: 'alternate', hreflang: 'fr', href: 'https://tiktokvoice.net/fr/sounds-effect' },
        { rel: 'alternate', hreflang: 'ro', href: 'https://tiktokvoice.net/ro/sounds-effect' },
        { rel: 'alternate', hreflang: 'cs', href: 'https://tiktokvoice.net/cs/sounds-effect' },
        { rel: 'alternate', hreflang: 'es', href: 'https://tiktokvoice.net/es/sounds-effect' },
        { rel: 'alternate', hreflang: 'pt', href: 'https://tiktokvoice.net/pt/sounds-effect' },
        { rel: 'alternate', hreflang: 'bn', href: 'https://tiktokvoice.net/bn/sounds-effect' },
        { rel: 'alternate', hreflang: 'it', href: 'https://tiktokvoice.net/it/sounds-effect' },
        { rel: 'alternate', hreflang: 'ar', href: 'https://tiktokvoice.net/ar/sounds-effect' },
        { rel: 'alternate', hreflang: 'ur', href: 'https://tiktokvoice.net/ur/sounds-effect' },
        { rel: 'alternate', hreflang: 'ms', href: 'https://tiktokvoice.net/ms/sounds-effect' },
        { rel: 'alternate', hreflang: 'tr', href: 'https://tiktokvoice.net/tr/sounds-effect' },
        { rel: 'alternate', hreflang: 'pl', href: 'https://tiktokvoice.net/pl/sounds-effect' },
        { rel: 'alternate', hreflang: 'nl', href: 'https://tiktokvoice.net/nl/sounds-effect' },
        { rel: 'alternate', hreflang: 'uk', href: 'https://tiktokvoice.net/uk/sounds-effect' },
      ]
    }
  },
  methods: {
    async generateSound() {
      if (this.isGenerating) return;

      const audio = this.$refs.audio_main;
      if (audio && !audio.paused) {
        audio.pause();
        audio.currentTime = 0;
      }

      this.showAudioControls = false;
      this.isGenerating = true;
      this.isPlaying = false;

      if (!this.description?.trim()) {
        alert("please type or paste text")
        return false
      }

      const formdata = {
          text: this.description,
          email: this.email,
          subscript: this.user_subscript,
          userid: 0,
        }

      try {
        const uri = `${api_host}/sapi/gensound`
        const { data } = await axios.post(uri, formdata, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache'
            },
        })
        switch (data.ret) {
          case 0:
            await Promise.all([
              this.updateAudioElements(`${data.uri}`),
              // this.updateCharacterCounter(data.textlen)
            ])
            this.showAudioControls = true
            trackAction({
                email: this.email,
                action: 'sounds-gen-audio',
                domain: 'tiktokvoice.net',
                modelcat: 'soundeffect',
                modelname: 'soundeffect'
            });
            break
          case 2:
            const popid = this.isLoggedIn
              ? (this.user_subscript === 2 ? STATIC_CONFIG.POPUP_TYPES.SUBLIMIT : STATIC_CONFIG.POPUP_TYPES.NOSUB)
              : STATIC_CONFIG.POPUP_TYPES.NOLOGIN
            this.openPopup(popid)
            trackAction({
                email: this.email,
                action: 'sounds-gen-popup',
                domain: 'tiktokvoice.net',
                modelcat: 'soundeffect',
                modelname: 'soundeffect'
            });
            break
          default:
            trackAction({
                email: this.email,
                action: 'sounds-gen-failed',
                domain: 'tiktokvoice.net',
                modelcat: 'soundeffect',
                modelname: 'soundeffect'
            });
            alert(data.msg)
          }
      } catch (error) {
        reportError(error, 'generator sound error')
        alert('An error occurred while generating the sound, please try again later!')
      } finally {
        this.isGenerating = false;
      }
    },
    async updateAudioElements(audioUrl) {
      try {
        this.generatedSound = audioUrl
        this.showAudioControls = true
        this.isPlaying = false
      } catch (error) {
        reportError(error, 'updateAudioElements');
      }
    },
    async playAudio(audioElement, audioSource) {
      if (!audioElement || !audioSource) {
        reportError(new Error('Audio element or source not found'), 'playAudio');
        return;
      }
      try {
        if (!audioElement.paused) {
          await audioElement.pause();
        }
        audioElement.currentTime = 0;
        audioElement.src = `${api_host}${audioSource}`;

        await new Promise((resolve, reject) => {
          const loadHandler = () => {
            audioElement.removeEventListener('canplay', loadHandler);
            audioElement.removeEventListener('error', errorHandler);
            resolve();
          };
          const errorHandler = (error) => {
            audioElement.removeEventListener('canplay', loadHandler);
            audioElement.removeEventListener('error', errorHandler);
            reject(error);
          };
          audioElement.addEventListener('canplay', loadHandler);
          audioElement.addEventListener('error', errorHandler);
          audioElement.load();
        });

        await audioElement.play();
      } catch (error) {
        reportError(error, 'playAudio - play sound failed');
      }
    },
    playSound() {
      const audio = this.$refs.audio_main;
      if (!audio || !this.generatedSound) {
        reportError(new Error('Audio element or source not found'), 'playSound');
        return;
      }

      // 如果正在播放,则暂停
      if (this.isPlaying) {
        audio.pause();
        this.isPlaying = false;
        // 重置音频到开始位置
        audio.currentTime = 0;
        return;
      }

      this.isPlayLoading = true;
      this.playAudio(audio, this.generatedSound)
        .then(() => {
          this.isPlaying = true;
          // 监听播放结束事件
          audio.onended = () => {
            this.isPlaying = false;
            audio.currentTime = 0; // 重置到开始位置
            audio.onended = null;
          };
        })
        .catch(error => {
          reportError(error, 'playSound - playback failed');
          this.isPlaying = false;
          audio.currentTime = 0; // 发生错误时也重置
        })
        .finally(() => {
          this.isPlayLoading = false;
        });
    },
    async handleDownload() {
        if (!this.generatedSound) {
          alert('There is no audio! Please generate sound first!');
          return;
        }
        if (this.isDownloading) {
          return;
        }

        if (!this.isLoggedIn) {
          this.isSignInVisible = true;
          trackAction({
              email: this.email,
              action: 'sounds-downpopup-login',
              domain: 'tiktokvoice.net',
              modelcat: 'soundeffect',
              modelname: 'soundeffect'
            });
          return;
        }
        if (this.user_subscript !== 1) {
          try {
            const uri = `${api_host}/lapi/actioncounts`
            const params = {};
            params.action = 'sounds-download-success';
            if (this.email) {
              params.email = this.email;
            }
            const { data } = await axios.get(uri, {
              params,
              headers: { 'Content-Type': 'application/json; charset=utf-8' },
              timeout: 3000  // 3s超时
            })

            if (data.ret === 0 && data.count >= 1) {
              // 当日下载超过2次， 显示弹窗
              this.$refs.popupDownload.openPopup();
              trackAction({
                  email: this.email,
                  action: 'sounds-downpopup-subscript',
                  domain: 'tiktokvoice.net',
                  modelcat: 'soundeffect',
                  modelname: 'soundeffect'
              });
              return ;
            }
          } catch (error) {
            reportError(error, 'handleDownload user subscript!')
          }
        }
        this.isDownloading = true;
        let url;
        try {
          const response = await fetch(`${api_host}${this.generatedSound}`, {
            headers: {
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache'
            },
            mode: 'cors',
            credentials: 'same-origin'
          });

          if (!response.ok) {
            reportError(new Error(`Download failed with status: ${response.status}`), 'handleDownload');
            alert('Sounds download failed, Please try later!');
            return;
          }

          const blob = await response.blob();
          if (!blob || blob.size === 0) {
            reportError(new Error('Invalid blob data received'), 'handleDownload');
            alert('Sounds blob download failed, Please try later!');
            return;
          }

          // 创建一个隐藏的下载链接
          const a = document.createElement('a');
          url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = `tiktokvoice.net-${new Date().getTime()}.mp3`; // 设置文件名
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          await trackAction({
            email: this.email,
            action: 'sounds-download-success',
            domain: 'tiktokvoice.net',
            modelcat: 'soundeffect',
            modelname: 'soundeffect'
          });
        } catch (error) {
          reportError(error, 'handleDownload down error');
          alert('Failed to download audio file, please try later!');
        } finally {
          // 清理 Blob URL
          if (url) {
            window.URL.revokeObjectURL(url);
          }
          this.isDownloading = false;
        }
    },
    selectPrompt(promptKey) {
      // 直接使用英文原文作为生成提示词
      this.description = promptKey.replace('soundeffect.prompt_', '');
    },
    toggleFaq(index) {
      this.openFaqIndex = this.openFaqIndex === index ? null : index;
    },
    async playSample(sample) {
      const audio = this.$refs.audio_main;

      // 如果正在加载,不响应点击
      if (this.isLoadingSample === sample.id) {
        return;
      }

      // 如果点击当前正在播放的音频
      if (this.currentPlaying === sample.id) {
        audio.pause();
        this.currentPlaying = null;
        return;
      }

      // 如果有其他音频在播放,先停止
      if (this.currentPlaying) {
        audio.pause();
        this.currentPlaying = null;
      }

      // 开始加载新的音频
      this.isLoadingSample = sample.id;

      try {
        audio.src = sample.url;

        // 等待音频加载完成
        await new Promise((resolve, reject) => {
          const loadHandler = () => {
            audio.removeEventListener('canplay', loadHandler);
            audio.removeEventListener('error', errorHandler);
            resolve();
          };
          const errorHandler = (error) => {
            audio.removeEventListener('canplay', loadHandler);
            audio.removeEventListener('error', errorHandler);
            reject(error);
          };
          audio.addEventListener('canplay', loadHandler);
          audio.addEventListener('error', errorHandler);
          audio.load();
        });

        // 开始播放
        await audio.play();
        this.currentPlaying = sample.id;

        // 监听播放结束
        audio.onended = () => {
          this.currentPlaying = null;
          audio.onended = null;
        };

      } catch (error) {
        console.error('播放样本失败:', error);
        this.currentPlaying = null;
      } finally {
        this.isLoadingSample = null;
      }
    },
    // 格式化当前时间
    currentTime() {
      return this.formatTime(this.$refs.audio_main?.currentTime || 0)
    },

    // 格式化总时长
    duration() {
      return this.formatTime(this.$refs.audio_main?.duration || 0)
    },

    // 格式化时间
    formatTime(seconds) {
      if (!seconds || isNaN(seconds)) return '0:00'
      const mins = Math.floor(seconds / 60)
      const secs = Math.floor(seconds % 60)
      return `${mins}:${secs.toString().padStart(2, '0')}`
    },

    // 更新进度条
    updateProgress() {
      if (!this.isDragging && this.$refs.audio_main) {
        const audio = this.$refs.audio_main
        this.audioCurrentTime = audio.currentTime
        this.audioDuration = audio.duration
        this.progress = (audio.currentTime / audio.duration) * 100 || 0
      }
    },

    // 点击进度条跳转
    seek(event) {
      const audio = this.$refs.audio_main
      if (!audio || !audio.duration || isNaN(audio.duration)) return

      const container = this.$refs.progressContainer
      const rect = container.getBoundingClientRect()
      const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width))
      const percentage = (x / rect.width) 

      const newTime = percentage * audio.duration
      if (isFinite(newTime)) {  // 确保时间值是有限数
        audio.currentTime = newTime
      }
    },

    // 开始拖动
    startDrag(event) {
      this.isDragging = true
      document.addEventListener('mousemove', this.drag)
      document.addEventListener('mouseup', this.stopDrag)
    },

    // 拖动中
    drag(event) {
      if (!this.isDragging || !this.$refs.progressContainer) return;

      const container = this.$refs.progressContainer;
      const rect = container.getBoundingClientRect();
      const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
      const percentage = (x / rect.width) * 100;

      this.progress = percentage;
    },

    // 停止拖动
    stopDrag() {
      if (!this.isDragging) return

      const audio = this.$refs.audio_main

      if (audio && audio.duration && isFinite(audio.duration)) {
        const newTime = (this.progress / 100) * audio.duration
        if (isFinite(newTime)) {  // 确保时间值是有限数
          audio.currentTime = newTime
        }
      }
      this.isDragging = false
      document.removeEventListener('mousemove', this.drag)
      document.removeEventListener('mouseup', this.stopDrag)
    },
    // 播放音频时初始化时长
    initAudioDuration() {
      const audio = this.$refs.audio_main
      if (audio) {
        this.audioDuration = audio.duration
      }
    },

    // 在音频加载完成时设置持续时间
    handleAudioLoaded() {
      const audio = this.$refs.audio_main
      if (audio) {
        this.audioDuration = audio.duration
      }
    },
    async handleCredentialResponse(response) {
      try {
        const apiUrl = `${api_host}/lapi/auth/google`
        const success = await handleGoogleAuth?.handleCredentialResponse?.(response, this.$store, apiUrl)
        if (success) {
          this.isSignInVisible = false
          trackAction({
            email: this.email,
            action: 'sounds-login-success',
            domain: 'tiktokvoice.net',
            modelcat: 'soundeffect',
            modelname: 'soundeffect'
          });
        } else {
          trackAction({
            email: this.email,
            action: 'sounds-login-failed',
            domain: 'tiktokvoice.net',
            modelcat: 'soundeffect',
            modelname: 'soundeffect'
          });
          reportError(new Error('Sounds login failed'), 'Sounds handleCredentialResponse failed')
          this.$emit('login-error', 'Sounds login failed')
        }
      } catch (error) {
        reportError(error, 'Sounds handleCredentialResponse')
      }
    },
    initializePopups() {
      Object.keys(this.popupData).forEach(id => {
        this.popupStates[id] = false
      })
    },
    openPopup(id) {
      if (this.popupData[id]) {
        this.popupStates[id] = true
      }
    },
    closePopup(id) {
      if (this.popupStates[id] !== undefined) {
        this.popupStates[id] = false
      }
      this.$forceUpdate();
    },
    async getUserInfo (email) {
      if (!email) {
        // console.log("email is empty, email:" + email)
        return false
      }
      try {
        const uri = `${api_host}/lapi/user/profile`
        const { data } = await axios.get(uri, {
          params: { email },
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
          }
        })

        if (data.ret === 0 && data.user_info) {
          this.email = data.user_info.email
          this.user_subscript = data.user_info.user_subscript
        } else {
          console.log("ret:" + data.ret + ", msg:" + data.msg)
        }
      } catch (error) {
        reportError(error, 'getUserInfo')
      }
    }
  },
  mounted() {
    this.pricing_href = '/' + this.$store.state.lang + '/pricing'
    const audio = this.$refs.audio_main
    if (audio) {
      audio.addEventListener('timeupdate', this.updateProgress)
      audio.addEventListener('loadedmetadata', this.handleAudioLoaded)
      audio.addEventListener('canplay', this.initAudioDuration)
    }
    if (this.isLoggedIn && this.currentUser) {
      this.getUserInfo(this.currentUser.email)
    }
    this.initializePopups()
    this.errorHandler = (event) => reportError(event.error, 'window.error');
    this.rejectionHandler = (event) => reportError(event.reason, 'unhandledrejection');
    window.addEventListener('error', this.errorHandler);
    window.addEventListener('unhandledrejection', this.rejectionHandler);
  },
  beforeUnmount() {
    const audio = this.$refs.audio_main
    if (audio) {
      audio.removeEventListener('timeupdate', this.updateProgress)
      audio.removeEventListener('loadedmetadata', this.handleAudioLoaded)
      audio.removeEventListener('canplay', this.initAudioDuration)
    }
    window.removeEventListener('error', this.errorHandler);
    window.removeEventListener('unhandledrejection', this.rejectionHandler);
  },
}
</script>

<style scoped>
.container {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0.98) 100%);
}

.main {
  width: 100%;
}

.sound-effect-page {
  min-height: calc(100vh - 120px);
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.main.sound-effect-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-section {
  position: relative;
  padding: 0;
  background: #ffffff;
  overflow: hidden;
}

.hero-content {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #2196F3, #4CAF50);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
}

.hero-content,
.content-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  box-sizing: border-box;
}

.hero-subtitle {
  font-size: 1.2rem;
  color: #757575;
}

.sound-samples-wrapper {
  position: relative;
  background: linear-gradient(to bottom, rgba(33, 150, 243, 0.05), rgba(76, 175, 80, 0.05));
  padding: 0;
  box-shadow: 0 8px 24px -12px rgba(0, 0, 0, 0.15),
              0 -8px 24px -12px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-left: none;
  border-right: none;
  margin: 24px 0;
}

.sound-samples {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sound-samples-title {
  font-size: 24px;
  color: #64B5B0;
  text-align: center;
  margin-bottom: 24px;
}

.categories-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 24px;
  justify-content: center;
  width: 100%;
  padding: 0 16px;
}

.category-tab {
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  background: rgba(0, 0, 0, 0.04);
  color: #333;
  flex: 0 1 auto;
  min-width: 110px;
  text-align: center;
}

/* 自然音效 - hover和active状态 */
.category-tab[data-category="nature"]:hover {
  background: rgba(16, 185, 129, 0.15);
  color: #05855B;
}
.category-tab[data-category="nature"].active {
  background: #05855B;
  color: white;
  box-shadow: 0 2px 8px rgba(16, 185, 129, 0.3);
}

.sound-cards-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  margin-top: 24px;
  padding: 0 16px;
}

.sound-card {
  background: #fff;
  border-radius: 12px;
  padding: 14px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  cursor: pointer;
}

/* 自然音效卡片 - 加深绿色系 */
.sound-card[data-category="nature"] {
  box-shadow: 0 2px 8px rgba(5, 150, 105, 0.12);
}
.sound-card[data-category="nature"]:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(5, 150, 105, 0.2);
}

.sound-card-content {
  display: flex;
  align-items: center;
  gap: 16px;
  min-height: 48px;
}
.sound-title {
  font-size: 15px;
  color: #333;
  margin: 0;
  line-height: 1.4;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
}
.sound-title span.emoji {
  font-size: 28px;
  line-height: 1;
  flex-shrink: 0;
}
.sound-title span.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}
.play-btn {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
  pointer-events: none;
}
.play-btn svg {
  width: 24px;
  height: 24px;
}
.play-btn {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #2196F3;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  flex-shrink: 0;
}

.play-btn[data-category="nature"] {
  background: #05855B;
}
.play-btn[data-category="nature"]:hover {
  background: #059669;
}
.loading-icon,
.pause-icon,
.play-icon {
  display: block;
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) {
  .hero-content,
  .sound-samples,
  .content-container {
    padding: 0 16px;
  }

  .hero-section {
    padding: 0;
  }

  .sound-samples-wrapper {
    margin: 16px 0;
    padding: 0;
  }

  .sound-samples {
    padding: 20px 16px;
  }

  .sound-cards-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0 12px;
  }
  .sound-card {
    padding: 12px;
  }

  .categories-tabs {
    gap: 8px;
    padding: 0 12px;
  }
  
  .category-tab {
    padding: 10px 16px;
    font-size: 16px;
    min-width: 95px;
    font-weight: 700;
    background: rgba(0, 0, 0, 0.06);
    letter-spacing: 0.3px;
  }

  .category-tab.active {
    font-weight: 700;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  }

  .play-btn svg {
    width: 20px;
    height: 20px;
  }

  .play-btn {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .hero-content,
  .sound-samples,
  .content-container {
    padding: 10px 12px;
  }

  .hero-section {
    padding: 0;
  }

  .sound-samples-wrapper {
    margin: 12px 0;
    padding: 0;
  }

  .sound-samples {
    padding: 20px 12px;
  }

  .sound-cards-grid {
    grid-template-columns: 1fr;
  }
}

@import url('/ssr/css/soundeffect3.css');
</style>
